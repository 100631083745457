<template>
<!-- 运动员——运动员名单 -->
	<div>
		<div class="container darkBack">
			<div class="font20 whiteFont">运动员名单</div>
			<!-- 选择器 -->
			<!-- <div>
				<ul id="selectUl" class="lightBlueFont">
					<li v-for="(item,index) in select" :key="index">
						<div>{{item}}</div>
						<div class="triangle"></div>
					</li>
				</ul>
			</div> -->
			<!-- 运动员列表 -->
			<vueSeamlessScroll :data="sportList" class="seamlessWarp" :class-option="classOption">
				<div class="sportList">
					<!-- 单个运动员 -->
					<div class="sportItem" v-for="(item,index) in sportList" :key="index">
						<div class="sportInfo">
							<div class="blueFont font20">
								{{item.name}}
								<img class="sexIcon" :src="item.img">
							</div>
						</div>
						<div class="tags">
							<div class="tag" v-for="(tag,num) in item.tags" :key="num">{{tag}}</div>
						</div>
					</div>
				</div>
			</vueSeamlessScroll>
		</div>
	</div>
</template>

<script>
import {getAjax, timestamp} from "/src/assets/js/websocket";
	import vueSeamlessScroll from 'vue-seamless-scroll'
	export default {
		components:{
			vueSeamlessScroll
		},
		data() {
			return{
				select:{
					city:'浙江省',
					project:"项目",
					sex:"性别",
					level:"级别"
				},
					
				sportList:[]
				
			}
		},
		methods:{
			// 获取数据
			getData(){
				let that = this;
				var data = {
					token: window.getToken(),
					app_id: window.appId,
					time: timestamp(),
				}
				getAjax({
					url: "/map/screen/getHomeSportsman",
					method:"GET",
					data:data,
					success:function(res){
						// console.log(res)
						that.sportList = [];
						res.data.sportsman_main_list.data.forEach((item,index)=>{
							let tag = [];
							item.project_ids.forEach((son,num)=>{
								tag.push(son.project_value + ' ' + son.project_level)
							})
							that.sportList.push({
								name:item.name,
								tags:tag,
								img:item.sex == 1 ? require('../../../assets/images/manIcon.png') 
												: require('../../../assets/images/womanIcon.png')
							})
						})
					},
				})
			}
		},
		computed: {
		    classOption () {
		       return {
		        step: 0.4, // 数值越大速度滚动越快
		        limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
		        hoverStop: true, // 是否开启鼠标悬停stop
		        direction: 1, // 0向下 1向上 2向左 3向右
		        openWatch: true, // 开启数据实时监控刷新dom
		        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
		        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
		        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
		      }
		    }
		},
		mounted() {
			this.getData();
		}
	}
</script>

<style scoped>
	.container{
		width: 5.96rem;
		height: 7rem;
		position: relative;
		padding: 0.2rem;
	}
	#selectUl{
		margin-top: 0.3rem;
		margin-left: 0.3rem;
		width: 80%;
		display: flex;
		justify-content: space-around;
	}
	#selectUl > li {
		display: flex;
		align-items: center;
		justify-content: space-around;
	}
	.triangle{
		width: 0rem;
		height: 0rem;
		border-top: 0.11rem solid #7EA7E2;
		border-left: 0.11rem solid transparent;
		border-right: 0.11rem solid transparent;
		margin: 0.05rem 0rem 0rem 0.1rem;
		cursor:pointer;
	}
	.sportList{
		margin-top: 0.4rem;
	}
	.sportItem{
		width: 80%;
		margin-left: 10%;
		margin-top: 0.2rem;
	}
	.sportInfo{
		display: flex;
		justify-content: space-between;
	}
	.sexIcon{
		width: 0.17rem;
		height: 0.17rem;
		margin-left: 0.1rem;
	}
	.tags{
		margin-top: 0.1rem;
		display: flex;
		flex-wrap: wrap;
	}
	.tag{
		font-size: 0.11rem;
		color: #FBAD57;
		padding: 0.03rem 0.06rem;
		height: 0.19rem;
		line-height: 0.20rem;
		text-align: center;
		border: 0.01rem solid #FBAD57;
		border-radius: 0.05rem;
		margin-right: 0.1rem;
		margin-top: 0.1rem;
	}
	.seamlessWarp{
		height:5.6rem;
		overflow: hidden;
		margin-top: 0.1rem;
	}
</style>